import React from 'react';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/Seo';
import Theme from '../components/Theme/Theme';
import PaymentSuccess from '../components/PaymentSuccess/PaymentSuccess';

const Success = () => (
    <Layout>
        <SEO title="Success" />
        <Theme>
            <PaymentSuccess />
        </Theme>
    </Layout>
);

export default Success;
